* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  height: 100%;
  /* font-size: 62.5%; */
}

/* Todas las fuentes y medidas rem y em dentro del documento haran referencia a la medida de la fuente dentro del html etiqueta */
body {
  line-height: 1.5;
  min-height: 100%;
}

@media (min-width: 768px) {
  html {
    font-size: 100%;
  }
}

#PDF {
  color: black;
  background-color: #e2e8f0;
  padding: 10px;
  margin: 50px;
  border-radius: 5px;
  font-family: inherit;
  font-weight: bold;
}

/* 
##############################
  ESTILOS DEL LOGIN
##############################
*/

.contenedor__auth--main {
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  height: 100vh;
  width: 100vw;
}

.contenedor__auth--box {
  width: 100%;
}

.contenedor__form--main {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 25rem;
  height: 35rem;
  background: #61dafb;
  border-radius: 20px;
  box-shadow: 6px 6px 15px -3px rgb(102, 102, 102, 0.6);
  overflow: hidden;
}

.contenedor__form--main::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
  background: linear-gradient(to top, #f5f0f0 65%, transparent);
}

.bg__login--img {
  width: 100%;
  height: 3.5rem;
  background: url(../img/logo-doblej.png) no-repeat center/contain;
}

.contenedor__form--contenido {
  position: absolute;
  bottom: 0;
  z-index: 2;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

.form__login--box {
  font-size: 1rem;
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.form__login--input {
  height: 2rem !important;
}

.logo__login--img {
  width: 100%;
  height: 60px;
  background-image: url(../img/logotipo-negro.png);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

/* 
##############################
  ESTILOS DEL HOME SCREEN
##############################
*/

.usuario__data--box {
  background-color: #d6eaea;
  box-shadow: -1px 1px 7px 0px rgba(0, 0, 0, 0.45);
  padding: 1rem;
  border-radius: 6px;
}

/* 
##############################
  ESTILOS DEL PERFIL SCREEN
##############################
*/

/* 
##############################
  ESTILOS DEL SPINNER LOAD
##############################
*/

.chakra-button__spinner {
  background-color: transparent;
}

/* 
##############################
  ESTILOS BARRA DE NAVEGACION
##############################
*/

.css-17pwl6t {
  width: auto;
  background: rgba(224, 223, 223, 0.5);
}

.button_navegacion--item {
  background-color: #0bc5ea;
  color: white;
}

/* 
##############################
  ESTILOS DEL REGISTRAR
##############################
*/

.contenedor__form--register {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50rem;
  height: 40rem;
  background: #61dafb;
  border-radius: 20px;
  box-shadow: 6px 6px 15px -3px rgb(102, 102, 102, 0.6);
  overflow: hidden;
}

.contenedor__form--register::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
  background: linear-gradient(to top, #f5f0f0 65%, transparent);
}

.bg__register--img {
  position: absolute;
  top: -60px;
  width: 100%;
  height: 300px;
  background: url(../img/abma.jpg) center / cover;
}

.contenedor__form--contenido-register {
  position: absolute;
  bottom: 0;
  z-index: 2;
  width: 100%;
  height: 65%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

.form__register--box {
  font-size: 1rem;
  width: 90%;
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.form__register--input {
  height: 2rem !important;
  width: 90%;
}

.logo__register--img {
  width: 100%;
  height: 60px;
  background-image: url(../img/logotipo-negro.png);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

.side-by-side {
  display: flex;
  flex-direction: row;
}

/* 
##############################
  ESTILOS DEL DATEPICKER
##############################
*/

.custom-datepicker {
  background-color: #0bc5ea;
  border: none;
  padding: 1rem;
  border-radius: 5px;
  cursor: pointer;
}

.custom-datepicker:hover {
  background-color: #0056b3;
  color: #fff;
}

/* 
##############################
  ESTILOS CUADRO DE VIDEO Y OVERLAY EN TOMAR UN NUEVO ARCHIVO SCREEN
##############################
*/

.video-component {
  position: relative;
}

#my-video {
  width: 100%;
  height: 100%;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background-color: rgba(0, 0, 0, 0.5); */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  color: #ffffff;
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  padding: 1rem;
}

.rotate-button {
  display: none;
}

@media (max-width: 600px) {
  .rotate-button {
    display: block;
  }
}

@media (min-width: 992px) {
  .rotate-button {
    display: none !important;
  }
}
